import React, { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Notification, NotificationCarousel } from "../../components/profile";
import { UserAccount } from "../../components/profile";
import { Button, Divider, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { SubUserForm } from "../../components/profile";
import { parentBoxStyles } from "../../styles/box";
import { Logout } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useQuery } from "@apollo/client";
import { GET_NOTIFICATION_BY_USER_ID } from "../../gql/notifications";
import { GET_SUB_USERS_BY_PARENT_ID } from "../../gql/sub-user";
import { ProfileSetting } from "../../components/profile/ProfileSetting";
import { ConfirmDialog } from "../../components/ConfirmDialog";

const subAccountsData = [
  { label: "Mother", value: "A0024", icon: <Avatar /> },
  { label: "Wife", value: "A0026", icon: <Avatar /> },
  { label: "Brother", value: "A0028", icon: <Avatar /> },
  { label: "Sister", value: "A0029", icon: <Avatar /> },
];

const commonStyles = {
  a: { display: "flex", paddingTop: "30px", flexDirection: "column", width: '100%' },
  b: { fontFamily: "Inter", fontSize: 15, fontWeight: "bold" },
  c: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
};

export const ProfilePage: FC = () => {
  const navigate = useNavigate();
  const { currentUser, logout, isLoggedIn } = useAuth();
  const [name, setName] = useState('');

  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  useEffect(() => {
    if (!isLoggedIn) navigate('/login?returnUrl=/profile');
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (currentUser) {
      const userInfo = currentUser?.profileInfo?.userInfo;
      let userName = currentUser?.phoneNumber;
      if (userInfo?.firstName) {
        userName = userInfo?.firstName + " " + (userInfo?.lastName ?? '');
      }
      setName(userName);
    }
  }, [currentUser]);



  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [openProfileSetting, setOpenProfileSetting] = useState(false);
  const { data: notificationsData } = useQuery(GET_NOTIFICATION_BY_USER_ID, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });
  const notifications = notificationsData?.getNotificationByUserId;
  const { data: subUsersData } = useQuery(GET_SUB_USERS_BY_PARENT_ID, {
    variables: {
      parentId: currentUser?.profileInfo?.id
    }
  });
  const subUsers = subUsersData?.getSubUserByParentId;


  const renderSubAccounts = () => {
    if (subUsers && subUsers.length > 0) {
      return subUsers?.map((item: any, index: number) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={12}
          gap={1}
          my={1}
          justifyContent={"center"}
        >
          <Paper
            key={index}
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: 14, fontWeight: "bold", color: "#4F0336" }}
              >
                {item.firstName} {item.lastName}
              </Typography>
              <Typography variant="body2" color="#777777" sx={{ fontSize: 12 }}>
                DOB: {item.dob}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: 14, fontWeight: "bold", color: "#777777" }}
              >
                {item.referenceNo}
              </Typography>
              <Typography variant="body2" color="#777777" sx={{ fontSize: 12 }}>
                Relation: {item.relation}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ));
    } else {
      return (
        <Box>
          <Typography
            component="span"
            sx={{ fontSize: 14, color: "#777777" }}
          >
            You do not have any sub accounts added.
          </Typography>
        </Box>
      );
    }
  };

  const logOut = async () => {
    // Show confirmation dialog before logout
    setIsLogoutDialogOpen(true);
  };

  return (
    <Box pt={7}>
      <Box sx={parentBoxStyles} style={{ backgroundImage: "none" }}>
        <Box
          sx={{
            backgroundColor: "white",
            flexWrap: "wrap",
            gap: "10px",
            borderRadius: "0px 0px 20px 20px",
            display: "contents",
          }}
        >
          <Box sx={{ display: "flex", paddingY: "10px" }}>
            <Avatar
              alt="Profile Picture"
              src={currentUser?.profileInfo?.userInfo?.profilePic || require("../../images/profile.png")}
              sx={{ width: 80, height: 80, mb: 2 }}
            />
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "12px" }}
            >
              <Typography
                variant="body2"
                color="#777777"
                sx={{ fontFamily: "Roboto", fontSize: 15, fontWeight: "bold" }}
              >
                Hello,
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                sx={{ fontFamily: "Roboto", fontSize: 20, fontWeight: "bold" }}
              >
                {" "}
                {name}
              </Typography>
            </Box>
          </Box>
          {notifications && notifications.length > 0 && (
            <Box
              sx={{
                backgroundColor: "white",
                display: "contents",
                mt: 2,
              }}
            >
              <Box textAlign={"left"} width={"100%"}>
                <Typography
                  variant="body1"
                  color="#777777"
                  sx={{ ...commonStyles.b }}
                >
                  Notifications
                </Typography>
              </Box>
              <Box style={{ width: "100%" }}>
                {<NotificationCarousel notifications={notifications} />}
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ ...commonStyles.a }}>
          <Typography
            variant="body1"
            color="#777777"
            sx={{ ...commonStyles.b }}
          >
            Your Account
          </Typography>
          {<UserAccount />}
        </Box>
        {/* <Box sx={{ ...commonStyles.a }}>
          <Typography
            variant="body1"
            color="#777777"
            sx={{ ...commonStyles.b }}
          >
            Sub Accounts
          </Typography>
          <Grid container>
            {renderSubAccounts()}
          </Grid>
          <SubUserForm
            open={isUserFormOpen}
            onClose={() => setIsUserFormOpen(false)}
          />
        </Box> */}

        <Box
          sx={{
            ...commonStyles.a,
            flexDirection: "column",
            width: "100%",
            pt: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* <Button
            variant="contained"
            sx={{
              mt: 1,
              backgroundColor: "#4F0336",
              "&:hover": { backgroundColor: "#390227" },
            }}
            onClick={() => setIsUserFormOpen(true)}
          >
            <Typography textTransform={"none"}>Add Sub User</Typography>
          </Button> */}
            <Button
              variant="contained"
              sx={{
                mt: 1,
                backgroundColor: "#4F0336",
                "&:hover": { backgroundColor: "#390227" },
              }}
              onClick={() => navigate("/my-account")}
            >
              <Typography
                color="white"
                textTransform={"none"}
                fontWeight={"normal"}
                fontSize={16}
              >
                My Account
              </Typography>
            </Button>
            {currentUser && !currentUser.isStaff && !currentUser.isManager && (
              <Button
                variant="contained"
                sx={{
                  mt: 1,
                  backgroundColor: "#4F0336",
                  "&:hover": { backgroundColor: "#390227" },
                }}
                onClick={() => setOpenProfileSetting(true)}
              >
                <Typography
                  color="white"
                  textTransform={"none"}
                  fontWeight={"normal"}
                  fontSize={16}
                >
                  Profile Settings
                </Typography>
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                mt: 1,
                backgroundColor: "#4F0336",
                "&:hover": { backgroundColor: "#390227" },
                mb: 2
              }}
              onClick={() => logOut()}
            >
              <Typography
                color="white"
                textTransform={"none"}
                fontWeight={"normal"}
                fontSize={16}
              >
                Logout
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      {isLogoutDialogOpen && (<ConfirmDialog
        open={isLogoutDialogOpen}
        onClose={() => setIsLogoutDialogOpen(false)}
        onConfirm={async () => {
          await logout();
          localStorage.removeItem("accessToken");
          window.location.reload();
        }}
        message="Are you sure you want to sign out?"
      />)}

      {openProfileSetting && (<ProfileSetting open={openProfileSetting} onClose={() => setOpenProfileSetting(false)} />)}

    </Box>
  );
};
