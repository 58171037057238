import { gql } from "@apollo/client";

export const GET_APP_SETTING = gql`
    query GetAppSetting {
        getAppSetting {
            id
            banner
            promotionOne
            promotionTwo
            promotionThree
            promotionFour
            promotionFive
            promotionSix
            seetuPromotionOne
            seetuPromotionTwo
            seetuPromotionThree
            winnerBanner
        }
    }
`;