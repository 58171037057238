import { Box, Typography, Link as MuiLink } from "@mui/material";
import { Facebook, Instagram, InfoOutlined, PhoneCallbackOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const LargeScreenFooterComponent = () => {
  const navigate = useNavigate();

  const iconStyle = { fontSize: "28px", color: "#4F0336", marginRight: "8px" };

  const socialIcons = [
    { src: Facebook, link: 'https://www.facebook.com/Amaaralondon' },
    { src: Instagram, link: 'https://www.instagram.com/amaarajewellery_london' },
  ];

  const commonTypographyStyles = {
    fontSize: "13px",
    color: "#4F0336",
    fontWeight: 600,
    lineHeight: 1.5
  };

  const commonIconStyles = {
    width: "32px",
    height: "32px",
    marginRight: "12px"
  };

  return (
    <Box sx={{ backgroundColor: "#f2eee5" }}>
      {/* Box1 - Upper Section */}
      <Box sx={{ 
        padding: { xs: "1.5rem", md: "2.5rem 3rem 1rem" },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        backgroundColor: "white",
        gap: 4
      }}>
        {/* Left Content */}
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5
        }}>
          <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => navigate('/store')}>
            <img src="/Loc.svg" alt="Store Locations" style={commonIconStyles} />
            <Typography sx={commonTypographyStyles}>Store Locations</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => navigate('/t&c')}>
            <img src="/Term.svg" alt="Terms and Conditions" style={commonIconStyles} />
            <Typography sx={commonTypographyStyles}>Terms and Conditions</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => navigate('/about-us')}>
            <InfoOutlined style={iconStyle} />
            <Typography sx={commonTypographyStyles}>About Us</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => navigate('/contact-us')}>
            <PhoneCallbackOutlined style={iconStyle} />
            <Typography sx={commonTypographyStyles}>Contact Us</Typography>
          </Box>
        </Box>

        {/* Center Logo */}
        <Box sx={{ 
          flex: 1.2,
          display: "flex", 
          justifyContent: "center",
          alignItems: "center",
          py: 2
        }}>
          <img 
            src={require('../../images/amaaraOriginal.png')} 
            alt="Amaara Logo" 
            style={{ width: "280px", objectFit: "contain" }} 
          />
        </Box>

        {/* Right Content */}
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}>
          <Box sx={{ 
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 2.5
          }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={require('../../images/916.png')} alt="Hallmark" style={{ width: "48px", height: "22px", marginRight: "12px" }} />
              <Typography sx={{ ...commonTypographyStyles, fontWeight: 600 }}>British Hallmarked</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={require('../../images/delivery.png')} alt="Shipping" style={{ width: "48px", height: "22px", marginRight: "12px" }} />
              <Typography sx={{ ...commonTypographyStyles, fontWeight: 600 }}>Insured Shipping & Delivery</Typography>
            </Box>
            
            {/* Social Icons */}
            <Box sx={{ display: "flex", gap: 2, mt: 7 }}>
              {socialIcons.map((icon, index) => (
                <MuiLink href={icon.link} target="_blank" key={index} sx={{ 
                  cursor: "pointer",
                  '&:hover': { opacity: 0.8 }
                }}>
                  <icon.src sx={{ fontSize: 32, color: "#4F0336" }} />
                </MuiLink>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Box2 - Lower Section */}
      <Box sx={{ 
        padding: { xs: "1rem", md: "1.5rem 2rem" },
        textAlign: "center",
        backgroundColor: "#ae8e9d",
      }}>
        <Typography variant="body1" sx={{ 
          color: "#4F0336",  
          fontWeight: 600,
        }}>
          AMAARA JEWELLERY (Trading name of K Gold Limited)
        </Typography>
        <Typography sx={commonTypographyStyles}>
          15 Ealing Road, Wembley, Middlesex, HA0 4AA, Ph: 0207 460 6100, Email: info@amaarajewellery.co.uk  
        </Typography>
        <Typography sx={commonTypographyStyles}>
         VAT Registration: 350495107
        </Typography>
      </Box>
    </Box>
  );
};
