import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Divider, Box, Grid } from '@mui/material';
import GetDirectionsIcon from '@mui/icons-material/Directions';
import { parentBoxStyles } from '../../styles/box';
import { buttonStyles } from '../../styles/button';

export const Store: React.FC = () => {
  const handleGetDirections = () => {
    window.open('https://www.dshort.in/l/22f7cc2f-869a-4b54-addc-7cf695ee6464', '_blank');
  };

  return (
    <Box pt={7} pb={2}>
       <Box
        sx={{
          width: "100%",
          height: { xs: '40vh', sm: '50vh', md: '92vh' },
          backgroundImage: `url(${process.env.PUBLIC_URL}/shop.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Grid container>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Box sx={parentBoxStyles} style={{ backgroundImage: "none" }}>
            <Typography
              variant="h1"
              fontSize={40}
              fontFamily={"ABC Gravity"}
              fontWeight={700}
              color="#4f0336"
              sx={{ pb: 2 }}
            >
              Our Shop
            </Typography>

            <Grid container spacing={2} mt={2}>
              {/* Jewelry Shop Card */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card sx={{ maxWidth: 450, pb: 1 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="180"
                      image="/shop1.jpg"
                      alt="Jewelry Shop"
                      sx={{ objectFit: 'fill' }}
                    />
                    <CardContent sx={{ pb: 1 }}>
                      <Typography gutterBottom variant="h5" component="div">
                        Amaara Jewellery
                      </Typography>
                      {/* <Divider
                        sx={{ backgroundColor: "#4F0336" }}
                      /> */}

                      <Box marginTop={2}>
                        <Typography variant="body1">
                          15 Ealing Rd, Wembley HA0 4AA, United Kingdom
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          marginTop={2}
                        >
                          +44 20 7460 6100
                        </Typography>
                      </Box>

                      {/* <Divider
                        sx={{ backgroundColor: "#4F0336", height: 1 }}
                      /> */}
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      onClick={handleGetDirections}
                      sx={[
                        buttonStyles,
                        {
                          width: {
                            xs: "100%",
                          },
                        },
                      ]}
                    >
                      <GetDirectionsIcon />
                      Get Directions
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  sx={{
                    width: "100%",
                    maxWidth: 450,
                    textAlign: "center",
                    backgroundColor: "#F2EEE5",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      color="#4f0336"
                      sx={{ marginBottom: 2 }}
                    >
                      Opening Hours
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginY: 1 }}
                    >
                      <b style={{ color: '#4F0336' }}>Monday:</b> 10:30 am – 6:30 pm
                    </Typography>
                    <Divider sx={{ backgroundColor: "#4F0336", height: 1 }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginY: 1 }}
                    >
                      <b style={{ color: '#4F0336' }}>Tuesday:</b> Closed
                    </Typography>
                    <Divider sx={{ backgroundColor: "#4F0336", height: 1 }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginY: 1 }}
                    >
                      <b style={{ color: '#4F0336' }}>Wednesday:</b> 10:30 am – 6:30 pm
                    </Typography>
                    <Divider sx={{ backgroundColor: "#4F0336", height: 1 }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginY: 1 }}
                    >
                      <b style={{ color: '#4F0336' }}>Thursday:</b> 10:30 am – 6:30 pm
                    </Typography>
                    <Divider sx={{ backgroundColor: "#4F0336", height: 1 }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginY: 1 }}
                    >
                      <b style={{ color: '#4F0336' }}>Friday:</b> 10:30 am – 6:30 pm
                    </Typography>
                    <Divider sx={{ backgroundColor: "#4F0336", height: 1 }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginY: 1 }}
                    >
                      <b style={{ color: '#4F0336' }}>Saturday:</b> 10:30 am – 6:30 pm
                    </Typography>
                    <Divider sx={{ backgroundColor: "#4F0336", height: 1 }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginY: 1 }}
                    >
                      <b style={{ color: '#4F0336' }}>Sunday:</b> 11:30 am – 6:30 pm
                    </Typography>
                    <Divider sx={{ backgroundColor: "#4F0336", height: 1 }} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
