import { createContext, useContext, useState, useEffect, FC } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";

import { auth } from "../config/firebase";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_BY_PHONE } from "../gql/user";
import { Loader } from "../components/loader";
import { useNavigate } from 'react-router-dom';
import { ErrorDialog } from "../components/errorDialog";

const AuthContext = createContext<any>(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: FC<any> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [getUserByPhoneQuery] = useLazyQuery(GET_USER_BY_PHONE);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false); // State for error dialog
  const [errorMessage, setErrorMessage] = useState(""); // State to hold error message
  useEffect(() => {
    const token = localStorage.getItem("accessToken") ? true : false;
    setIsLoggedIn(token);
  });

  let recaptchaVerifier: any;
  const signInWithPhone = (phone: string) => {
    if (!recaptchaVerifier) {
      recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: (response: any) => {
          console.log(response);
        },
        "expired-callback": () => {
          console.log("Expired");
        },
      });
    }
    return signInWithPhoneNumber(auth, phone, recaptchaVerifier);
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
      if (user) {
        const phoneNumber = user?.phoneNumber;
        const getUserByPhoneResult = await getUserByPhoneQuery({
          variables: {
            phone: phoneNumber,
          },
          onError: async (error) => {
            console.log(error);
            setErrorMessage("We are facing some issues in logging you in. Please try after sometime.");
            setOpenErrorDialog(true); // Open error dialog
            await logout();
            localStorage.removeItem("accessToken");
            setLoading(false);
            window.location.href = '/';
          }
        });
        const loggedInUser = getUserByPhoneResult?.data?.getUserByPhone;
        user.getIdToken().then((token: string) => {
          localStorage.setItem("accessToken", token);
          user.profileInfo = loggedInUser;
          if (loggedInUser?.role?.name === 'ADMIN') {
            user.isAdmin = true;
          } else if (loggedInUser?.role?.name === 'MANAGER') {
            user.isManager = true;
          } else if (loggedInUser?.role?.name === 'STAFF') {
            user.isStaff = true;
          }
          setCurrentUser(user);
          setLoading(false);
        });
      } else {
        setLoading(false);
        localStorage.removeItem("accessToken");
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    error,
    setError,
    signInWithPhone,
    logout,
    recaptchaVerifier,
    isLoggedIn,
  };

  return (
    <>
    <AuthContext.Provider value={value}>
      {!loading ? children : (
        <Loader />
      )}
    </AuthContext.Provider>
    <ErrorDialog
        open={openErrorDialog}
        close={() => setOpenErrorDialog(false)}
        message={errorMessage}
        isSuccess={false}
      />
    </>
    
  );
};
