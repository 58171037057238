import { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
import { cardCommonStyles, customHeadingStyle, customBodyTextStyle } from "../../styles/card-text";
import { parentBoxStyles } from "../../styles/box";


const BulletPoint = ({ text }: { text: string }) => {
  const [heading, content] = text.split(': ');
  
  return (
    <Typography 
      sx={{ 
        ...customBodyTextStyle,
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
        '&:before': {
          content: '"•"',
          marginRight: '8px',
          minWidth: '12px',
          marginTop: '-2px'
        }
      }}
    >
      <Box 
        component="span" 
        sx={{ 
          display: 'inline',
          '& span': {
            fontWeight: 700,
            marginRight: '4px'
          }
        }}
      >
        <span>{heading}</span>:{' '}{content}
      </Box>
    </Typography>
  );
};

export const TnC: FC = () => {
  return (
    <Box 
      sx={{ 
        pt: 9,
        maxWidth: '100%',
        overflowX: 'hidden'
      }}
    >
      <Box 
        sx={{
          ...parentBoxStyles,
          mx: 'auto',
          width: '100%',
          paddingX: { xs: 2, sm: "10%", md: "15%", lg: "20%" },
        }}
      >
        <Box>
          <Typography
            variant="h1"
            fontSize={28}
            fontFamily={"ABC Gravity"}
            fontWeight={700}
            color="#4f0336"
            sx={{ pb: 3 }}>
            Terms & Conditions
          </Typography>
        </Box>
        <Box>
          <Card className="card" sx={cardCommonStyles}>
            <Typography sx={customHeadingStyle}>
              Introduction
            </Typography>
            <Typography sx={customBodyTextStyle}>
              At Amaara Jewellery, we believe that exquisite gold jewellery should be within everyone's reach. 
              That's why we offer a stunning collection of high-quality 22kt Gold Jewellery at a competitive prices, 
              along with Flexible Saving Seetu to make your dream purchase effortless and rewarding.
            </Typography>
          </Card>
        </Box>
        <Box>
          <Card className="card" sx={cardCommonStyles}>
            <Typography sx={customHeadingStyle}>
              General
            </Typography>
            <Typography sx={customBodyTextStyle}>
              Welcome to Amaara Gold Seetu Saving Plan! Our exclusive Gold Seetu Saving Plan is crafted to help you 
              turn your jewellery dreams into reality through convenient monthly instalments. By joining our Gold 
              Seetu Saving Plan, you agree to the following terms and conditions:
            </Typography>

            <Box>
              {[
                'Duration: Amaara Gold Seetu Saving Plan runs for up to 15 months, allowing you ample time to save for your desired 22kt Gold Jewellery.',
                'Gold Price: The gold price for jewellery purchases will be based on the prevailing rate on the day of purchase. We are not responsible for any potential price fluctuations or related losses.',
                'Payment Flexibility: Customers can choose not to make a payment for any given month without penalty.',
                'Account Closure: In exceptional cases, Seetu accounts may be closed upon management approval. Refunds will be provided exclusively in the form of jewellery only except exceptional cases.',
                'Refund Policy: Cash payments towards monthly deposits are non-refundable. Refunds are only available in the form of 22kt Gold Jewellery purchases.',
                'Additional Bonus: An additional bonus of £100 is available for end-of-term collections, subject to specific conditions.',
                'Redemption Limitations: Bullion Bars, and coins are generally excluded from redemption at the end of the term, except at the sole discretion of management.',
                'Payment Responsibility: Customers making payments via bank transfer are responsible for ensuring that payments are received before the end of the month.',
                'Liability Disclaimer: K Gold Limited T/A Amaara Jewellery bears no liability for losses arising from fluctuations in gold prices or changes to seetu operations. Participants acknowledge that Gold Seetu Saving Plan is not regulated by the FCA and agree to the terms and conditions stated.',
                'Data Protection: Customer KYC details will be held in accordance with GDPR laws for future reference.',
                'Compliance: Participants must adhere to all seetu rules and regulations.',
                'Changes to Terms: We reserve the right to change the terms and conditions of the seetu without prior notice.',
                'Complaints: For any complaints, please contact us at info@amaarajewellery.co.uk.'
              ].map((text, index) => (
                <BulletPoint key={index} text={text} />
              ))}
            </Box>

            <Typography sx={customBodyTextStyle}>
              By opening an account, you acknowledge that you have read and agreed to the terms and conditions outlined above. 
              For any further queries please contact us at:
            </Typography>

            <Box>
              {[
                'Company Name: Amaara Jewellery (Trading name of K Gold Limited)',
                'Registration: Registered in England and Wales, Company Number: 12395568',
                'Address: 15 Ealing Road, Middlesex, HA0 4AA',
                'Telephone: 0207 460 6100',
                'Email: info@amaarajewellery.co.uk',
                'VAT Registration: 350495107'
              ].map((text, index) => (
                <BulletPoint key={index} text={text} />
              ))}
            </Box>

            <Typography sx={customBodyTextStyle}>
              Please review these terms and conditions carefully before registering for Gold Seetu Saving Plan. 
              We are committed to providing a transparent and rewarding experience for our customers.
            </Typography>
          </Card>
        </Box>
        <Box>
          <Card className="card" sx={cardCommonStyles}>
            <Typography sx={customHeadingStyle }>
              Cookies
            </Typography>
            <Typography variant="body1" sx={customBodyTextStyle}>
              A "cookie" is a small piece of information stored by a web server
              on a web browser so it can be later read back from that browser.
              No personal information will be collected via cookies and other
              tracking technology. However, if you previously provided
              personally identifiable information, cookies may be tied to such
              information. Aggregate cookie and tracking information may be
              shared with third parties.
            </Typography>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
