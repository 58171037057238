import React from 'react';
import './Loader.css'; 

export const Loader: React.FC = () => {
  return (
    <div className="loader-overlay">
      <div
        className="loader"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
}


