import { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
import {
  cardCommonStyles,
  customHeadingStyle,
  customBodyTextStyle,
} from "../../styles/card-text";
import { parentBoxStyles } from "../../styles/box";

export const AboutUs: FC = () => {
  return (
    <Box 
      pt={9} 
      sx={{
        maxWidth: '100%',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
      }}
    >
      <Box sx={parentBoxStyles}>
        <Box>
          <Typography
            variant="h1"
            fontSize={28}
            fontFamily={"ABC Gravity"}
            fontWeight={700}
            color="#4f0336"
            sx={{ pb: 3 }}>
            About Us
          </Typography>
        </Box>
        <Box id="about-section">
          <Card className="card" sx={cardCommonStyles}>
            <Typography sx={customBodyTextStyle}>
              At Amaara Jewellery, we believe everyone deserves to own stunning, high-quality 22kt Hallmarked Gold Jewellery. That's why we offer an extensive collection at competitive prices, along with flexible payment plan to make your purchases more accessible.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              As a family-owned business with over 20 years of expertise in the gold industry, we are dedicated to delivering an exceptional shopping experience. Our knowledgeable team is always ready to assist with any queries you may have.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              Crafted with premium materials and impeccable craftsmanship, our 22kt Gold Jewellery comes with a 100% satisfaction guarantee and 22kt British Hallmark. To ensure a seamless experience, we offer a variety of convenient shipping and return options. At Amaara Jewellery, we bring your jewellery dreams to life with trust and transparency.
            </Typography>
          </Card>
        </Box>
        <Box id="saving-plans-section">
          <Card className="card" sx={cardCommonStyles}>
            <Typography sx={customHeadingStyle}>Gold Seetu Saving Plan</Typography>
            <Typography sx={customBodyTextStyle}>
              We offer a range of flexible and affordable saving plan designed to make your dreams of owning 22kt Hallmarked Gold Jewellery to reality. These plans can be tailored to suit your individual needs and budget.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              One of our most popular options is the Monthly Instalment Plan (MIP), allowing you to pay for your jewellery over several months without any interest charges.
            </Typography>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
