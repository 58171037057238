import { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
import { cardCommonStyles, customHeadingStyle, customBodyTextStyle } from "../../styles/card-text";
import { TeaserCarousel } from "../../components/teaser-carousel";
import { useQuery } from "@apollo/client";
import { GET_APP_SETTING } from "../../gql/app-setting";

const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <Typography 
    sx={{ 
      ...customBodyTextStyle, 
      marginY: '8px', 
      color: '#4f0336',
      fontWeight: 700 
    }}
  >
    {children}
  </Typography>
);

const BulletPoint = ({ text }: { text: string }) => {
  const [heading, content] = text.split(': ');
  
  return (
    <Typography 
      sx={{ 
        ...customBodyTextStyle,
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
        '&:before': {
          content: '"•"',
          marginRight: '8px',
          minWidth: '12px',
          marginTop: '-2px' // Align bullet with text
        }
      }}
    >
      <Box 
        component="span" 
        sx={{ 
          display: 'inline',
          '& span': {
            fontWeight: 700,
            marginRight: '4px' // Space after heading
          }
        }}
      >
        <span>{heading}</span>:{' '}{content}
      </Box>
    </Typography>
  );
};

export const Scheme: FC = () => {
  const { data: settingsData } = useQuery(GET_APP_SETTING);
  const seetuImages = [
    settingsData?.getAppSetting?.seetuPromotionOne,
    settingsData?.getAppSetting?.seetuPromotionTwo,
    settingsData?.getAppSetting?.seetuPromotionThree
  ];

  const images = seetuImages.filter(Boolean).length > 0 
    ? seetuImages 
    : ["/schemeCarousel 1.png", "/schemeCarousel 2.png", "/schemeCarousel 3.png"];

  return (
    <Box 
      sx={{ 
        pt: 7, 
        pb: 4,
        maxWidth: '100%',
        overflowX: 'hidden'
      }}
    >
      <Box>
        <TeaserCarousel images={images} />
      </Box>

      <Box>
        <Box
          component="img"
          src="/seetuPage.png"
          alt="seetu scheme"
          width="100%"
          display={{ xs: "none", sm: "block" }}
        />
        <Box
          component="img"
          src="/seetuPageMobile.png"
          alt="seetu scheme"
          width="100%"
          display={{ xs: "block", sm: "none" }}
        />
      </Box>

      <Box
        sx={{
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "inline-flex",
          py: 2,
          mx: 'auto',
          width: '100%',
          paddingX: { xs: 2, sm: "10%", md: "15%", lg: "20%" },
        }}
      >
        <Card className="card" sx={[cardCommonStyles, { mb: 0 }]}>
          <Typography sx={customHeadingStyle}>Gold Seetu Saving Plan </Typography>
          
          <Typography sx={customBodyTextStyle}>
          Our exclusive Gold Seetu Savings Plan is crafted to transform your dreams into reality. 
          With our Gold Seetu Saving Plan, you can effortlessly save for your favourite gold pieces 
          through convenient monthly instalments tailored to suit your budget and lifestyle. 
          It’s a simple and rewarding way to build your savings while indulging in the joy of owning 
          exquisite 22kt Gold Jewellery. Join Amaara Gold Seetu Saving Plan today and take the first 
          step towards a sparkling future. 
          </Typography>

          <SectionTitle>Why Choose the Amaara Gold Seetu Savings Plan?</SectionTitle>
          {[
            'Flexible Payment Options: Pay at your convenience, with no pressure or rigid deadlines.',
            'Monthly Instalments: Spread the cost over time with manageable monthly payments for a stress-free saving experience.',
            'Increased Purchasing Power: Gradually build your savings and enjoy the freedom to purchase more stunning 22kt Gold Jewellery.',
            'Additional Bonus: Receive a £100 bonus upon plan completion as our way of appreciating your loyalty.',
            'Advance Design Selection: Plan ahead and reserve your favourite jewellery designs, ensuring they are ready when you are.',
            'Multiple Seetu Slots: Save for different goals or loved ones simultaneously by opening multiple Seetu accounts.'
          ].map((text, index) => (
            <BulletPoint key={index} text={text} />
          ))}

          <SectionTitle>Eligibility Criteria</SectionTitle>
          {[
            'Age Requirement: Participants must be at least 18 years old and above to join the plan.',
            'Residency: The plan is open to individuals from the UK and other country.',
            "Identification: A valid government-issued ID, passport, or driver's licence is required for registration.",
            'Address Proof: Proof of address is mandatory and may include utility bills, rental agreements, or official correspondence.',
            'Registration: Customers must register directly with us to receive a unique Seetu Reference Number for payments.',
            'Nominee Authorisation: Participants must nominate a next of kin or relative to act on their behalf in emergencies or unforeseen situations.',
            'Compliance: Participants are required to adhere to all the Terms and Conditions of the plan.'
          ].map((text, index) => (
            <BulletPoint key={index} text={text} />
          ))}

          <SectionTitle>Participation Rules for Amaara Gold Seetu Saving Plan</SectionTitle>
          {[
            'Monthly Payment Deadline: Customers are required to make a payment of GBP 100 by end of month to ensure staying on track monthly.',
            'Seetu Duration: The seetu runs for a duration of 15 months from the start date.',
            'Deposit Process: Under this , individuals can make payments either by cash at our retail store located at 15 Ealing Road, Wembley, HA0 4AA, or by bank transfer at the time of registration. Customers are responsible for collecting payment receipts as proof of payment.',
            'Payment Proof: Customers are responsible for making payments on time to maintain their eligibility. Late payments may be accepted under special circumstances, subject to prior confirmation directly with us.'
          ].map((text, index) => (
            <BulletPoint key={index} text={text} />
          ))}
        </Card>
      </Box>
    </Box>
  );
};
