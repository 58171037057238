import React, { useState, useEffect } from 'react';
import { Header } from './components/header';
import './App.css';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { ContactUs } from './pages/ContactUs';
import { commonTextFieldStyle } from './styles/text-field';
import { PageNotFound } from './pages/404';
import { Loader } from './components/loader';
import { AboutUs } from './pages/AboutUs';
import { Scheme } from './pages/Scheme';
import { TnC } from './pages/T&C';
import { AuthProvider } from './contexts/AuthContext';
import { PwaPrompt } from 'react-ios-pwa-prompt-ts';
import { OpenAccount } from './pages/OpenAccount';
import { ProfilePage } from './pages/Profile';
import { NotificationPage } from './pages/Notification';
import { AccountSetting } from './pages/AccountSetting';
import { Raffle } from './pages/Raffle';
import { Store } from './pages/Store';
import { PaymentSuccess } from './pages/PaymentSuccess/internal';
import { CookieConsentDialog } from './components/cookie/internal';
import { Footer } from './components/footer';

function App() {
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            ...commonTextFieldStyle,
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#4F0336',
      },
      secondary: {
        main: '#F2EEE5',
      },
    },
  });

  const [loading, setLoading] = useState(true);
  const [cookieDialogOpen, setCookieDialogOpen] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (!loading) {
      const cookieTimeoutId = setTimeout(() => {
        const cookieAccepted = localStorage.getItem('cookieAccepted');
        if (!cookieAccepted) {
          setCookieDialogOpen(true);
        }
      }, 3000);

      return () => clearTimeout(cookieTimeoutId);
    }
  }, [loading]);

  const handleAccept = () => {
    document.cookie =
      'cookieBy=amaaraseetu; expires=' +
      new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toUTCString();
    localStorage.setItem('cookieAccepted', 'true');
    setCookieDialogOpen(false); // Close the dialog when accepted
  };

  const handleClose = () => {
    setCookieDialogOpen(false); // Close the dialog when manually closed
  };

  // const clearCacheData = () => {
  //   //if (caches) {
  //     caches?.keys().then((names) => {
  //       names.forEach((name) => {
  //         caches?.delete(name);
  //       });
  //     });
  //   //}
  // };

  // useEffect(() => {
  //   clearCacheData();
  // }, []);

  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     navigator.serviceWorker
  //       .register("/sw.js")
  //       .then((registration) => {
  //         console.log("Registration successful");
  //       })
  //       .catch((error) => {
  //         console.log("Service worker registration failed");
  //       });
  //   }
  // }, []);

  return (
    <AuthProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {loading && <Loader />}
          {!loading && (
            <>
              {cookieDialogOpen && (<CookieConsentDialog open={cookieDialogOpen} onClose={handleClose} onAccept={handleAccept} />)}
              <PwaPrompt promptOnVisit={1} copyTitle="Install Amaara Seetu App" timesToShow={1} copyClosePrompt="Close" permanentlyHideOnDismiss={true}/>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/404" element={<PageNotFound />} />
                <Route path="/login" element={<Login />} />
                <Route path="/seetu" element={<Scheme />} />
                <Route path="/raffle" element={<Raffle />} />
                {/* <Route path="/open-account" element={<OpenAccount />} /> */}
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/t&c" element={<TnC />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/notifications" element={<NotificationPage />} />
                <Route path="/my-account" element={<AccountSetting />} />
                <Route path="/store" element={<Store />} />
                <Route path="/payment-confirmation" element={<PaymentSuccess />} />
                {/* Introduce new routes above this */}
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Routes>
              <Footer shouldRender={!loading} />
            </>
          )}
        </ThemeProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
