import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Button,
} from "@mui/material";
import { parentBoxStyles } from "../../styles/box";
import { ExpandLess, ExpandMore, Diamond } from "@mui/icons-material";
import { PaymentTable } from "../../components/payment-table";
import { buttonStyles } from "../../styles/button";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { TopHeader } from "../../components/top-header";
import { useQuery } from "@apollo/client";
import { GET_USER_SCHEME_BY_USER_ID } from "../../gql/user-scheme";
import { PaymentForm } from "../../components/payment-form/internal";
import { Loader } from "../../components/loader";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type Input = {
  month: string;
  year: number;
  frequency: number;
};

type Output = {
  month: string;
  year: number;
};

const getNextMonthAndYear = ({ month, year, frequency }: Input): Output[] => {
  // Set the current month and year if undefined
  const currentDate = new Date();
  let monthYear: Output[] = [];
  year = year || currentDate.getFullYear();
  if (month) {
    let currentMonthIndex = monthNames.indexOf(month) + 1;

    if (currentMonthIndex >= 12) {
      currentMonthIndex = 0;
      year++;
    }

    if (currentMonthIndex === -1) {
      throw new Error("Invalid month name.");
    }

    if (frequency <= 0) {
      throw new Error("Frequency must be a positive number.");
    }
    for (let i = 0; i < frequency; i++) {
      const monthYearObj = {
        month: monthNames[currentMonthIndex],
        year: year,
      };
      monthYear.push(monthYearObj);
      currentMonthIndex++;

      if (currentMonthIndex >= 12) {
        currentMonthIndex = 0;
        year++;
      }
    }
  } else {
    const monthYearObj = {
      month: monthNames[currentDate.getMonth()],
      year: year,
    };
    monthYear.push(monthYearObj);
  }

  return monthYear;
};

export const AccountSetting = () => {
  const navigate = useNavigate();
  const { currentUser, isLoggedIn } = useAuth();
  const [name, setName] = useState("");
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const [schemeData, setSchemeData] = useState<any>();
  const referenceNumber = currentUser?.profileInfo?.referenceNo;
  const [mainAccountBalance, setMainAccountBalance] = useState(0);
  const [bonusBalance, setBonusBalance] = useState(0);

  const goToOpenAccount = () => {
    if (!isLoggedIn) navigate("/login?returnUrl=/open-account");
    else navigate("/open-account");
  };

  useEffect(() => {
    if (!isLoggedIn) navigate("/login?returnUrl=/profile");
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (currentUser) {
      const userInfo = currentUser?.profileInfo?.userInfo;
      let userName = currentUser?.phoneNumber;
      if (userInfo?.firstName) {
        userName = userInfo?.firstName + " " + (userInfo?.lastName ?? "");
      }
      setName(userName);
    }
  }, [currentUser]);

  const { data, loading } = useQuery(GET_USER_SCHEME_BY_USER_ID, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });

  const userSchemes = data?.getUserSchemeByUserId;

  useEffect(() => {
    let bonusAmount = 0;
    for (let i = 0; i < userSchemes?.length; i++) {
      if (userSchemes[i]?.payment?.length === 15) {
        bonusAmount += 100;
      }
    }
    setBonusBalance(bonusAmount);
  }, [userSchemes]);

  const [openScheme, setOpenScheme] = React.useState<number | null>(null);

  const handleClick = (schemeId: number) => {
    setOpenScheme((prevScheme) =>
      prevScheme === schemeId ? null : schemeId
    );
  };

  enum Status {
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
    REDEEMED = "REDEEMED",
  };

  const makePayment = (schemeData: any) => {
    const userScheme = {
      id: schemeData.id,
      userId: schemeData.userId,
      amount: 100,
      monthYear: getNextMonthAndYear({
        month: schemeData?.payment[schemeData?.payment?.length - 1]?.month,
        year: schemeData?.payment[schemeData?.payment?.length - 1]?.year,
        frequency: 1,
      }),
      createdBy: currentUser?.profileInfo?.id,
    };
    setSchemeData(userScheme);
    setOpenPaymentForm(true);
  };

  useEffect(() => {
    if (userSchemes) {
      let accountBalance = 0;
      for (let i = 0; i < userSchemes.length; i++) {
        const scheme = userSchemes[i];
        const payments = scheme.payment;
  
        // Check if the scheme is not redeemed
        if (scheme.status !== Status.REDEEMED && payments && payments.length > 0) {
          accountBalance += payments.length * 100;
        }
      }
      setMainAccountBalance(accountBalance);
    }
  }, [userSchemes, Status.REDEEMED]);
  

  return (
    <Box sx={{ pt: { xs: 7, sm: 8 } }}>
      <TopHeader title={"My Account"} link="/profile" />
      <Box sx={parentBoxStyles} style={{ backgroundImage: "none" }}>
        <Box sx={{ display: "flex", paddingY: "10px" }}>
          <Avatar
            alt="Profile Picture"
            src={
              currentUser?.profileInfo?.userInfo?.profilePic ||
              require("../../images/profile.png")
            }
            sx={{ width: 80, height: 80, mb: 2 }}
          />
          <Box
            sx={{ display: "flex", flexDirection: "column", padding: "12px" }}
          >
            <Typography
              variant="body2"
              color="#777777"
              sx={{
                fontFamily: "Roboto",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              Hello,
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              sx={{
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              {" "}
              {name}
            </Typography>
          </Box>
        </Box>
        {loading ? (
          <Box mt={4}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box textAlign={"left"} width={"100%"}>
              <Typography
                variant="body1"
                color="#777777"
                sx={{
                  display: "flex",
                  paddingTop: "20px",
                  flexDirection: "column",
                }}
              >
                Account Details
              </Typography>
            </Box>

            <Card
              sx={{
                marginTop: 2,
                width: "100%",
                borderRadius: "20px",
                border: "1px solid #4f0336",
              }}
            >
              <CardContent sx={{}}>
                <List>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="#777777"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        fontWeight: "regular",
                      }}
                    >
                      Account Number:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        fontWeight: "medium",
                      }}
                    >
                      {referenceNumber}
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="#777777"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        fontWeight: "regular",
                      }}
                    >
                      Account Balance:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        fontWeight: "medium",
                      }}
                    >
                      £{mainAccountBalance}
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="#777777"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        fontWeight: "regular",
                      }}
                    >
                      Bonus Balance:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        fontWeight: "medium",
                      }}
                    >
                      £{bonusBalance}
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="#777777"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        fontWeight: "regular",
                      }}
                    >
                      Redeemable Balance:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        fontWeight: "medium",
                      }}
                    >
                      £{mainAccountBalance + bonusBalance}
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
            </Card>

            <Box textAlign={"left"} width={"100%"}>
              <Typography
                variant="body1"
                color="#777777"
                sx={{
                  display: "flex",
                  paddingTop: "20px",
                  flexDirection: "column",
                }}
              >
                Account Statement
              </Typography>
            </Box>

            {(userSchemes && userSchemes.length === 0) || 
            (userSchemes && userSchemes.filter((item: any) => item.status !== 'REDEEMED').length === 0) ? (
              <Box
                sx={{
                  display: "flex",
                  m: 3,
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography fontStyle={"italic"} color="#4f0336">
                  You haven't opened any account yet.
                </Typography>
                {/* <Button
                  sx={[buttonStyles, { width: { xs: "100%" } }]}
                  onClick={goToOpenAccount}
                >
                  Open New Account
                </Button> */}
                 <Button
                  sx={[buttonStyles, { width: { xs: "100%" } }]}
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </Button>
              </Box>
            ) : (
              userSchemes?.map((scheme: any, index: number) => (
                <Box
                  key={scheme.id}
                  sx={{
                    marginTop: 2,
                    width: "100%",
                    borderRadius: "20px",
                    color: "#4f0336",
                    border: "1px solid #4f0336",
                  }}
                >
                  <ListItemButton onClick={() => handleClick(scheme.id)}>
                    <ListItemIcon>
                      <Diamond sx={{ color: "#4f0336" }} />
                    </ListItemIcon>
                    <ListItemText primary={`${scheme.referenceNumber} (${scheme.status})`} />
                    {openScheme === scheme.id ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {openScheme === scheme.id && (
                    <Box>
                      <PaymentTable
                        payments={scheme?.payment}
                        user={{ ...scheme.user, ...scheme.userInfo }}
                        schemeRef={scheme.referenceNumber}
                      />
                      {scheme?.payment?.length < 15 &&
                      scheme.status === Status.RUNNING ? (
                        <Box
                          sx={{
                            display: "flex",
                            mx: 6,
                            my: 2,
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            sx={{
                              ...buttonStyles,
                              width: "100%",
                              textTransform: "none",
                            }}
                            onClick={() => makePayment(scheme)}
                          >
                            Pay (
                            {
                              getNextMonthAndYear({
                                month:
                                  scheme?.payment[scheme?.payment?.length - 1]
                                    ?.month,
                                year: scheme?.payment[
                                  scheme?.payment?.length - 1
                                ]?.year,
                                frequency: 1,
                              })[0].month
                            }
                            )
                          </Button>
                        </Box>
                      ) : (
                        <>
                          {scheme.status === Status.COMPLETED ? (
                            <Box
                              sx={{
                                display: "flex",
                                m: 3,
                                justifyContent: "center",
                              }}
                            >
                              <Typography fontStyle={"italic"} color="#4f0336">
                                You've completed this scheme. We invite you to
                                visit our store to claim the benefits you've
                                earned.
                              </Typography>
                            </Box>
                          ) : (
                            <>
                              {scheme.status === Status.REDEEMED ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    m: 3,
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    fontStyle={"italic"}
                                    color="#4f0336"
                                  >
                                    You've redeemed this scheme.
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    m: 3,
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    fontStyle={"italic"}
                                    color="#4f0336"
                                  >
                                    You've reached the end of the tenure for
                                    this seetu. We invite you to visit our
                                    store to claim the benefits you've earned.
                                  </Typography>
                                </Box>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              ))
            )}
          </>
        )}
      </Box>
      <PaymentForm
        open={openPaymentForm}
        onClose={() => setOpenPaymentForm(false)}
        schemeData={schemeData}
      />
    </Box>
  );
};
