import { Box, Grid, Typography } from "@mui/material";

export const WhyBuy = () => {
  return (
    <Box sx={{ paddingX: { xs: 3, sm: 10 }, paddingTop: { xs: 3, sm: 6 } }}>
      <Box mb={5} textAlign={"center"}>
        <Typography
          color="#4F0336"
          fontWeight={"bold"}
          sx={{ fontSize: { xs: 30, sm: 40 } }}
        >
          Why buy from us?
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          justifyContent: "space-evenly",
        }}
      >
        <Grid item xs={12} sm={5}>
          <Box sx={flexStyled}>
            <img src="/1.svg" alt="01" height={40} />
            <Box>
              <Typography sx={headerStyled}>Purity is Our Promise</Typography>
              <Typography sx={textStyled}>
              We prioritise gold purity above all else, offering an extensive collection of 22kt 
              British Hallmarked Gold Jewellery backed by our satisfaction guarantee.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box sx={flexStyled}>
            <img src="/2.svg" alt="02" height={40} />
            <Box>
              <Typography sx={headerStyled}>
                Unique Design Collection
              </Typography>
              <Typography sx={textStyled}>
              Our designs define our identity. Explore a wide range of beautifully crafted 22kt 
                Gold Jewellery across various categories.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box sx={flexStyled}>
            <img src="/3.svg" alt="03" height={40} />
            <Box>
              <Typography sx={headerStyled}>Complete Transparency</Typography>
              <Typography sx={textStyled}>
              From purity, weight to our Gold Seetu Saving Plan, we ensure 100% transparency with our customers. 
              See it all, know it all, and make informed choices with confidence.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box sx={flexStyled}>
            <img src="/4.svg" alt="04" height={40} />
            <Box>
              <Typography sx={headerStyled}>Certified Quality</Typography>
              <Typography sx={textStyled}>
              All our products are 22kt British Hallmarked, 
              meeting the highest standards of quality, 
              rigorously tested and approved for your peace of mind.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const flexStyled = {
  display: "flex",
  gap: "16px",
  alignItems: "flex-start",
  marginBottom: { xs: 2, sm: 8 },
};
const headerStyled = {
  fontSize: { xs: 18, sm: 20, lg: 24 },
  color: "#777777",
  fontWeight: "bold",
  marginBottom: "8px",
};
const textStyled = {
  fontSize: { xs: 14, sm: 18, lg: 20 },
  color: "#777777",
  lineHeight: 2,
};
